import * as React from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { Nav } from '../nav/NavDrawer'

import headshot from '../../img/headshot.jpeg'

const useStyles = makeStyles({
  about: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10%',
  },
  container: {
    marginTop: '1%',
    marginBottom: '10%',
    marginLeft: '30%',
    marginRight: '30%',
  },
  paragraph: {
    fontWeight: 'normal',
  },
})

export const Home = () => {
  const [open, setOpen] = React.useState(window.innerWidth < 720 ? false : true)

  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setOpen(false)
    }
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <div>
      <Nav handleNav={handleClick} />
      <div
        className={classes.about}
        style={{
          paddingLeft: open ? '300px' : '0px',
          transform: 'scale(1)',
          transition: '0.25s all ease',
        }}
      >
        <Container className={classes.container}>
          <Typography variant="h3" paragraph>
            <strong>Introduction</strong>
          </Typography>
          <div>
            <Grid
              container
              spacing={8}
              // alignItems="center"
              // justifyContent="center"
            >
              <Grid item md={8}>
                <Typography variant="h4" paragraph>
                  Hi there! I'm Jenna Van Benschoten 👋
                </Typography>
                <Typography variant="h6" paragraph>
                  I'm a licensed pediatric occupational therapist. My
                  educational journey includes a psychology major at Rutgers
                  University for my undergraduate degree and a Master of
                  Occupational Therapy degree from Seton Hall University.
                  Currently, I am pursuing my Doctor of Occupational Therapy
                  degree at Marymount University.
                </Typography>
                <Typography variant="h6" paragraph>
                  My professional experience includes working full-time in
                  public school districts throughout Bergen County and part-time
                  engagement at a pediatric outpatient clinic. Having worked
                  with a diverse range of diagnoses spanning ages 3 to 21, I am
                  committed to advancing holistic child development through my
                  expertise in pediatric occupational therapy.
                </Typography>
              </Grid>
              <Grid item md={4}>
                <img src={headshot} alt="jenna" height="350px" />
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="h3" paragraph>
              <strong>The Motivation</strong>
            </Typography>

            <Grid
              container
              spacing={8}
              alignItems="center"
              justifyContent="left"
            >
              <Grid item md={12}>
                <Typography variant="h6" paragraph>
                  My newfound passion centers around advocating for the autism
                  community, particularly addressing the often overlooked
                  transition from high school to the workforce. Following high
                  school graduation, the support network for individuals within
                  the autism community tends to diminish, leaving them to face
                  substantial challenges in navigating the path to adulthood.
                  This critical phase reveals a noticeable deficit in assistance
                  and resources for those on the autism spectrum. Recognizing
                  this gap, I am dedicated to raising awareness and actively
                  working towards creating initiatives that focus on
                  facilitating a smoother transition for individuals with autism
                  into the workforce, beginning with a focus on travel training.
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Typography variant="h3" paragraph>
            <strong>The Need</strong>{' '}
          </Typography>
          <div>
            <Grid
              container
              spacing={8}
              alignItems="center"
              justifyContent="left"
            >
              <Grid item md={12}>
                <Typography variant="h6" paragraph>
                  In New Jersey, the adult population with Autism Spectrum
                  Disorder (ASD) is steadily increasing, ranking third highest
                  in the nation{' '}
                  <Link href="https://autismnj.org/understanding-autism/prevalence-rates/#:~:text=Autism%20Prevalece%20Rates&text=New%20Jersey%20now%20has%20the,8%2Dyear%2Dold%20childen.">
                    (Autism NJ, 2023)
                  </Link>
                  . As a result, legislatures implemented laws and regulations
                  that support this population with a focus on enhancing
                  community engagement; one example being the improvement of
                  public transportation. Transportation is a critical element of
                  society, enabling access to essential services and resources
                  as well as effectively connecting people. Most of the young
                  adult population (18-25 years of age) can participate in
                  community engagement because of the accessibility that public
                  transportation provides. However, for adults with autism,
                  access and usability of transportation can be the biggest
                  barrier to reaching full independence and autonomy. Feeley{' '}
                  <Link href="https://www.researchgate.net/publication/228977376_Evaluating_the_Transportation_Needs_and_Accessibility_Issues_for_Adults_on_the_Autism_Spectrum_in_New_Jersey">
                    (2010)
                  </Link>{' '}
                  cited major disparities in access to transportation services,
                  including communication, sensory processing, social
                  interaction, and executive functioning difficulties. In
                  addition, Lindsay{' '}
                  <Link href="https://www.tandfonline.com/doi/full/10.3109/09638288.2016.1161849">
                    (2017)
                  </Link>{' '}
                  indicated only 24% of adults with high functioning autism
                  possess a driver's license, facing specific challenges of
                  handling unexpected changes, sustaining attention for long
                  drives, and merging into traffic, as reported by driving
                  instructors. Lubin and Feeley{' '}
                  <Link href="https://journals.sagepub.com/doi/10.3141/2542-01">
                    (2016)
                  </Link>{' '}
                  explored the reliance on parents for transportation and the
                  desire for autonomy among adults with ASD. Parents reported
                  that the loss of school transportation after age 21
                  significantly impeded the ability of their adult children with
                  ASD to engage in daily occupations. This led to increased
                  feelings of isolation, periods of depression, and lack of
                  confidence due to complications with independent mobility
                  amongst this population{' '}
                  <Link href="https://journals.sagepub.com/doi/10.3141/2542-01">
                    (Lubin & Feeley, 2016)
                  </Link>
                  . Skilled instructional courses have the potential to provide
                  this population with the necessary knowledge, skills, and
                  confidence in order to navigate public transportation
                  independently for improved social inclusion.
                </Typography>
                {/* <Typography variant="h6" paragraph>
                My newfound passion centers around advocating for the autism community, 
                particularly addressing the often overlooked transition from high school 
                to the workforce. Following high school graduation, the support network 
                for individuals within the autism community tends to diminish, leaving 
                them to face substantial challenges in navigating the path to adulthood. 
                This critical phase reveals a noticeable deficit in assistance and resources 
                for those on the autism spectrum. Recognizing this gap, I am dedicated to 
                raising awareness and actively working towards creating initiatives that 
                focus on facilitating a smoother transition for individuals with autism 
                into the workforce, beginning with a focus on travel training.
              </Typography> */}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </div>
  )
}
