import * as React from 'react'

import { type DialogProps } from '@mui/material'

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Modal from '@mui/material/Modal'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Typography from '@mui/material/Typography'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  spacing: 0,
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 2,
}

export const SurveyModal = ({ modalOpen, modalLink }: SurveyModalProps) => {
  const [open, setOpen] = React.useState(modalOpen)
  const [intakeComplete, setIntakeComplete] = React.useState(false)
  const [preSurveyComplete, setPreSurveyComplete] = React.useState(false)
  const [buttonDisabled, setButtonDisabled] = React.useState(true)

  React.useEffect(() => {
    setButtonDisabled(!(intakeComplete && preSurveyComplete))
  }, [intakeComplete, preSurveyComplete])

  const handleButtonClick = () => {
    setOpen(false)
  }
  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return

    setOpen(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ backdropFilter: 'blur(2px)' }}
      >
        <Box
          sx={style}
          style={{
            transform:
              window.innerWidth > 720
                ? 'translate(-15%, -50%)'
                : 'translate(-50%, -50%)',
          }}
        >
          <Grid container alignContent={'center'} justifyContent={'center'}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              paddingBottom="15px"
            >
              <strong>Have you completed the following?</strong>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <FormControlLabel
                required
                control={
                  <Checkbox
                    style={{ color: '#1F4D63' }}
                    onClick={() => setIntakeComplete(!intakeComplete)}
                    icon={<AssignmentTurnedInOutlinedIcon />}
                    checkedIcon={<AssignmentTurnedInIcon />}
                  />
                }
                label={
                  <Link
                    href={'https://forms.gle/n19rHpGcahBshRGW8'}
                    target="_blank"
                    sx={{ color: '#1F4D63' }}
                  >
                    <strong>Intake Form</strong>
                  </Link>
                }
              />
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              paddingBottom="20px"
            >
              <FormControlLabel
                required
                control={
                  <Checkbox
                    style={{ color: '#1F4D63' }}
                    onClick={() => setPreSurveyComplete(!preSurveyComplete)}
                    icon={<AssignmentTurnedInOutlinedIcon />}
                    checkedIcon={<AssignmentTurnedInIcon />}
                  />
                }
                label={
                  <Link
                    href={modalLink}
                    target="_blank"
                    sx={{ color: '#1F4D63' }}
                  >
                    <strong>Pre-Course Survey</strong>
                  </Link>
                }
              />
            </Typography>
            <Button
              variant="contained"
              disabled={buttonDisabled}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => handleButtonClick()}
              sx={{
                width: '100%',
                background:
                  'linear-gradient(to right bottom, #1F4D63, #47989D)',
                '&:hover': {
                  backgroundColor: '#47989D',
                },
              }}
            >
              <strong>Continue to Modules</strong>
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}

interface SurveyModalProps {
  modalOpen: boolean
  modalLink: string
}
