import * as React from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { Nav } from '../nav/NavDrawer'
import { ModuleEntry } from './moduleEntry/ModuleEntry'

const useStyles = makeStyles({
  about: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    marginTop: '1%',
    marginBottom: '10%',
    marginLeft: '30%',
    marginRight: '30%',
  },
  paragraph: {
    fontWeight: 'normal',
  },
})

export const Resources = () => {
  const [open, setOpen] = React.useState(window.innerWidth < 720 ? false : true)

  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setOpen(false)
    }
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <div>
      <Nav handleNav={handleClick} />

      <div
        className={classes.about}
        style={{
          paddingLeft: open ? '300px' : '0px',
          transform: 'scale(1)',
          transition: '0.25s all ease',
        }}
      >
        <Container className={classes.container}>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item>
              <Typography variant="h3" paragraph>
                <strong>Resources</strong>
              </Typography>
            </Grid>
          </Grid>
          <ModuleEntry
            name={'Module 2: Trip Planning'}
            videos={[
              {
                description: 'Bus Customers: How Full is My Ride',
                href: 'https://www.youtube.com/watch?v=5z9IXZaxavs&t=1s',
              },
              {
                description: 'Train Customers: How Full is My Ride',
                href: 'https://www.youtube.com/watch?v=8IErgeZWIYI&t=1s',
              },
              {
                description: 'How to Buy Bus Tickets with the NJ Transit App',
                href: 'https://www.youtube.com/shorts/1Laj64e0a5k',
              },
              {
                description: 'How to Buy Train Tickets with the NJ Transit App',
                href: 'https://www.youtube.com/watch?v=cfk2WFM73cc&t=5s',
              },
              {
                description: '113 New Rail Cars from Bombardier',
                href: 'https://www.youtube.com/watch?v=wWG4GhScBL0&t=1s',
              },
            ]}
            websites={[
              {
                description: 'Bus Fare Charts',
                href: 'https://www.njtransit.com/bus-fare-charts',
              },
              {
                description: 'Trip Planner: Service Near a Location',
                href: 'https://www.njtransit.com/trip-planner-service-near-to',
              },
              {
                description: 'Trip Planner: Plan Your Trip',
                href: 'https://www.njtransit.com/trip-planner-to',
              },
            ]}
            worksheets={[]}
            resources={[
              {
                description: 'NJ Transit',
                href: 'https://www.njtransit.com',
              },
            ]}
          />
          <ModuleEntry
            name={'Module 3: Problem Solving and Decision Making'}
            videos={[]}
            websites={[]}
            worksheets={[
              {
                description: 'Goal-Plan-Do-Review Worksheet',
                href: 'https://www.parinc.com/Portals/0/Webuploads/samplerpts/Goal-Plan-Do-Review_Worksheet_BRIEF2_04_21_1218501271.pdf',
              },
            ]}
            resources={[
              {
                description: 'Goal-Plan-Do-Review / Revise',
                href: 'https://www.gpdrr.org',
              },
              {
                description: 'Contingency Planning',
                href: 'https://www.betterup.com/blog/contingency-planning',
              },
            ]}
          />
          <ModuleEntry
            name={'Module 4: Social Skills and Emotional Regulation'}
            videos={[
              {
                description: 'Square Breathing Visual',
                href: 'https://www.youtube.com/watch?v=bF_1ZiFta-E&t=20s',
              },
              {
                description:
                  'Guided Meditation for Stress Relief on Public Transportation',
                href: 'https://www.youtube.com/watch?v=ACJExAO88ZA&t=15s',
              },
              {
                description: 'Public Transport: Mindfulness Meditation',
                href: 'https://www.youtube.com/watch?v=RX1sN_GAahs&t=26s',
              },
              {
                description: 'Progressive Muscle Relaxation',
                href: 'https://www.youtube.com/watch?v=ClqPtWzozXs&t=82s',
              },
            ]}
            websites={[]}
            worksheets={[
              {
                description: 'Wipe Away My Worry: Printable',
                href: 'https://thehappinessplanner.com/collections/printables/products/printables-anxiety?utm_source=pinterest&utm_medium=social',
              },
              {
                description: 'How to Heal Anxiety',
                href: 'https://cognitiveheights.com/how-to-heal-anxiety',
              },
              {
                description: 'Self Care Journal',
                href: 'https://create.vista.com/templates/self-care-journal-in-pink/id-6362a854bd515db7288166c3/',
              },
            ]}
            resources={[
              {
                description: 'Box Breathing Benefits',
                href: 'https://health.clevelandclinic.org/box-breathing-benefits',
              },
              {
                description: 'Public Transit Safety and Etiquette',
                href: 'https://www.penndot.pa.gov/TravelInPA/PublicTransitOptions/Pages/Safety-and-Etiquette-Tips.aspx',
              },
              {
                description: 'Autism Spectrum Transportation Needs in NJ',
                href: 'https://www.researchgate.net/publication/228977376_Evaluating_the_Transportation_Needs_and_Accessibility_Issues_for_Adults_on_the_Autism_Spectrum_in_New_Jersey',
              },
              {
                description: 'Meditation and Mindfulness Overview',
                href: 'https://www.nccih.nih.gov/health/meditation-and-mindfulness-what-you-need-to-know',
              },
              {
                description: 'Verbal vs Nonverbal Communication',
                href: 'https://socialskillscenter.com/the-difference-between-verbal-and-nonverbal-communication',
              },
              {
                description: 'Anxiety and Self-Confidence Affirmations',
                href: 'https://www.suu.edu/blog/2022/03/affirmations-for-anxiety.html',
              },
              {
                description: 'Relaxation Techniques Effectiveness',
                href: 'https://doi.org/10.1155/2021/5924040',
              },
              {
                description: 'Understanding Situational Awareness',
                href: 'https://www.blackberry.com/us/en/solutions/critical-event-management/situational-awareness',
              },
            ]}
          />
          <ModuleEntry
            name={'Module 5: Resources and Support'}
            videos={[]}
            websites={[
              {
                description:
                  'Savings for Seniors, People with Disabilities, and Military',
                href: 'https://www.njtransit.com/first-run/savings-seniors-people-disabilities-military',
              },
              {
                description: 'The Arc Family Institute Support Groups',
                href: 'https://www.thearcfamilyinstitute.org/what_we_do/support-groups.html',
              },
              {
                description: 'Autism Support Groups in New Jersey',
                href: 'https://www.goldencaretherapy.com/wp-content/uploads/2020/01/GoldenCareTherapy.com-Autism-Support-Groups-in-New-Jersey.pdf',
              },
            ]}
            worksheets={[]}
            resources={[
              {
                description: 'NJ TRANSIT Reduced Fare Program',
                href: 'https://www.njtransit.com/schedules-and-fares/reduced-fare-program',
              },
              {
                description: 'Access Link ADA Paratransit',
                href: 'https://www.njtransit.com/accessibility/access-link-ada-paratransit',
              },
              {
                description: 'Community Transportation Options',
                href: 'https://www.njtransit.com/accessibility/community-transportation',
              },
              {
                description: 'Magnusmode for NJ TRANSIT',
                href: 'https://www.njtransit.com/magnusmode',
              },
              {
                description: 'New Jersey Travel Independence Program',
                href: 'https://vtc.rutgers.edu/njtip/',
              },
              {
                description: 'TransitConnect Travel Training - Ridewise',
                href: 'https://ridewise.org/transitconnect/',
              },
              {
                description:
                  'NJ Transportation Management Associations and Rideshare Info',
                href: 'https://www.nj.gov/transportation/commuter/rideshare/tma.shtm',
              },
            ]}
          />
        </Container>
      </div>
    </div>
  )
}
