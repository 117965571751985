import * as React from 'react'

import { type DialogProps } from '@mui/material'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  spacing: 0,
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 2,
}

export const ContactModal = ({
  modalOpen,
  modalHeader,
  modalBody,
}: ContactModalProps) => {
  const [open, setOpen] = React.useState(modalOpen)

  const handleButtonClick = () => {
    setOpen(false)
  }
  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return

    setOpen(false)
  }

  React.useEffect(() => {
    setOpen(modalOpen)
  }, [modalOpen])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ backdropFilter: 'blur(2px)' }}
      >
        <Box
          sx={style}
          style={{
            transform:
              window.innerWidth > 720
                ? 'translate(-15%, -50%)'
                : 'translate(-50%, -50%)',
          }}
        >
          <Grid container alignContent={'center'} justifyContent={'center'}>
            <Grid item>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                paddingBottom="15px"
              >
                <strong>{modalHeader}</strong>
              </Typography>
            </Grid>

            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              paddingBottom="15px"
            >
              {modalBody}
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleButtonClick()}
              sx={{
                width: '100%',
                background:
                  'linear-gradient(to right bottom, #1F4D63, #47989D)',
                '&:hover': {
                  backgroundColor: '#47989D',
                },
              }}
            >
              <strong>OK</strong>
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}

interface ContactModalProps {
  modalOpen: boolean
  modalHeader: string
  modalBody: string
}
