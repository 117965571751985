import React from 'react'

import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import { Contact } from './contact/Contact'
import { NotFound } from './error/404'
import { Home } from './home/Home'
import { Module } from './module/Module'
import { Resources } from './resources/Resources'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <div>
        <Routes>
          <Route path="*" Component={NotFound} />
          <Route path="/" Component={Home} />
          <Route
            path="/module-1"
            element={
              <Module
                name={'Module 1: Welcome and Introduction'}
                video={
                  'https://www.youtube.com/embed/y4q7Zeo6C6U?si=eOup7-9FYJFmd6OI'
                }
                description={
                  'Welcome to Module 1! For this module, we begin with an introduction to the course, a comprehensive overview, and course goals.'
                }
                hasSurvey={true}
                survey={'https://forms.gle/nSbtwDVVA7R7i9Fj6'}
                nextModule={'Module 2'}
                nextModuleHref={'/module-2'}
                target={''}
                previousModule={'Introduction'}
                previousModuleHref={'/'}
                pdfLink={'module-1-welcome-and-introduction.pdf'}
              />
            }
          />
          <Route
            path="/module-2"
            element={
              <Module
                name={'Module 2: Trip Planning'}
                video={
                  'https://www.youtube.com/embed/jEJ8erBLnxg?si=iBuXAsCWEmQY3yjh'
                }
                description={
                  'Welcome to Module 2! This module will guide you through using the NJ Transit App, understanding buses and trains, fares, ticket purchasing, and other necessary factors that are crucial for trip planning.'
                }
                hasSurvey={false}
                survey={''}
                nextModule={'Module 3'}
                nextModuleHref={'/module-3'}
                target={''}
                previousModule={'Module 1'}
                previousModuleHref={'/module-1'}
                pdfLink={'module-2-trip-planning.pdf'}
              />
            }
          />
          <Route
            path="/module-3"
            element={
              <Module
                name={'Module 3: Problem Solving and Decision Making'}
                video={
                  'https://www.youtube.com/embed/H8pEupEHe9k?si=UlNUtVhkdoftijyt'
                }
                description={
                  'Welcome to Module 3! This module will tackle problem solving and decision making, essential skills for navigating unexpected situations while traveling.'
                }
                hasSurvey={false}
                survey={''}
                nextModule={'Module 4'}
                nextModuleHref={'/module-4'}
                target={''}
                previousModule={'Module 2'}
                previousModuleHref={'/module-2'}
                pdfLink={'module-3-problem-solving-and-decision-making.pdf'}
              />
            }
          />
          <Route
            path="/module-4"
            element={
              <Module
                name={'Module 4: Social Skills and Emotional Regulation'}
                video={
                  'https://www.youtube.com/embed/pyYxH6mzw3Y?si=Fwro1O8hL770o0fA'
                }
                description={
                  'Welcome to Module 4! This module addresses emotional regulation difficulties by learning and practicing various calming strategies. In addition, communication challenges are targeted by discussing important aspects such as different forms of communication, social etiquette, self-advocacy, and safety awareness.'
                }
                hasSurvey={false}
                survey={''}
                nextModule={'Module 5'}
                nextModuleHref={'/module-5'}
                target={''}
                previousModule={'Module 3'}
                previousModuleHref={'/module-3'}
                pdfLink={'module-4-social-skills-and-emotional-regulation.pdf'}
              />
            }
          />
          <Route
            path="/module-5"
            element={
              <Module
                name={'Module 5: Resources and Support'}
                video={
                  'https://www.youtube.com/embed/9-moCTUt9iI?si=_j4GvJrgjwwE7zWv'
                }
                description={
                  'Welcome to Module 5! This module will cover supportive services, assistance programs, and support groups to ensure you have the network and support needed for successful travel.'
                }
                hasSurvey={false}
                survey={''}
                nextModule={'Module 6'}
                nextModuleHref={'/module-6'}
                target={''}
                previousModule={'Module 4'}
                previousModuleHref={'/module-4'}
                pdfLink={'module-5-resources-and-support.pdf'}
              />
            }
          />
          <Route
            path="/module-6"
            element={
              <Module
                name={'Module 6: Summary'}
                video={
                  'https://www.youtube.com/embed/0wrlKyysGy8?si=xkV2qR7rQHKudzTT'
                }
                description={
                  "Welcome to Module 6! This final module will be a review of all that we've learned, followed by course feedback and a post-survey to measure the impact of our training."
                }
                hasSurvey={false}
                survey={''}
                nextModule={'Post Survey'}
                nextModuleHref={'https://forms.gle/Cd8xJbUfTKDBVgcX9'}
                target={'_blank'}
                previousModule={'Module 5'}
                previousModuleHref={'/module-5'}
                pdfLink={'module-6-summary.pdf'}
              />
            }
          />
          <Route path="/resources" Component={Resources} />
          <Route path="/contact" Component={Contact} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
