import * as React from 'react'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import emailjs from '@emailjs/browser'

import { Nav } from '../nav/NavDrawer'
import { ContactModal } from '../Modals/contactModal'

const useStyles = makeStyles({
  about: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10%',
  },
  container: {
    marginTop: '1%',
    marginBottom: '10%',
    marginLeft: '30%',
    marginRight: '30%',
  },
  paragraph: {
    fontWeight: 'normal',
  },
})

export const Contact = () => {
  const [open, setOpen] = React.useState(window.innerWidth < 720 ? false : true)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalHeader, setModalHeader] = React.useState('Thank you!')
  const [modalBody, setModalBody] = React.useState(
    'Your question has been submitted!'
  )

  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  const onHandleSubmit = async (e: any) => {
    e.preventDefault()
    setModalOpen(!modalOpen)

    try {
      const result = await emailjs.sendForm(
        'service_0qcsj2i',
        'template_upsj2ww',
        e.currentTarget,
        'Jm-uTh56rK_L3ELnu'
      )

      // If the promise resolves successfully, execute this block
      e.target.reset()
    } catch (error: any) {
      // If the promise is rejected, this block will execute
      console.log(error.text)
      setModalHeader("We're Sorry!")
      setModalBody('An error occurred, please try again soon!')
      setModalOpen(!modalOpen)
    }
  }

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setOpen(false)
    }
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <div>
      <Nav handleNav={handleClick} />
      <div
        className={classes.about}
        style={{
          paddingLeft: open ? '300px' : '0px',
          transform: 'scale(1)',
          transition: '0.25s all ease',
        }}
      >
        <ContactModal
          modalOpen={modalOpen}
          modalHeader={modalHeader}
          modalBody={modalBody}
        />
        <Container className={classes.container}>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item>
              <Typography variant="h3" paragraph>
                <strong>Contact the Next Stop, Onward Instructor</strong>
              </Typography>
            </Grid>
            <Grid container alignContent="center" justifyContent="center">
              <Grid item>
                <Typography variant="h6" paragraph>
                  We're here to answer your questions and discuss feedback about
                  the program, let's talk!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <form onSubmit={onHandleSubmit}>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              spacing={2}
              padding={'1%'}
            >
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="first-name"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="last-name"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              padding={'1%'}
            >
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  name="emailAddress"
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              padding={'1%'}
            >
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              padding={'1%'}
            >
              <Grid item xs={8}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={false}
                  sx={{
                    background:
                      'linear-gradient(to right bottom, #1F4D63, #47989D)',
                    '&:hover': {
                      backgroundColor: '#1F4D63',
                    },
                    width: '100%',
                  }}
                >
                  <strong>Submit</strong>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    </div>
  )
}
