import * as React from 'react'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import HomeIcon from '@mui/icons-material/Home'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { Nav } from '../nav/NavDrawer'

const useStyles = makeStyles({
  about: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {},
  paragraph: {
    fontWeight: 'normal',
  },
})

export const NotFound = () => {
  const [open, setOpen] = React.useState(window.innerWidth < 720 ? false : true)

  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setOpen(false)
    }
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <div>
      <Nav handleNav={handleClick} />

      <div
        className={classes.about}
        style={{
          paddingLeft: open ? '300px' : '0px',
          transform: 'scale(1)',
          transition: '0.25s all ease',
        }}
      >
        <Container className={classes.container}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h2" paragraph>
                <strong>We lost this page</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" paragraph>
                Let's get back on track!
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              endIcon={<HomeIcon />}
              href="/"
              sx={{
                // borderRadius: 8,
                background:
                  'linear-gradient(to right bottom, #1F4D63, #47989D)',
              }}
            >
              <strong>go home</strong>
            </Button>
          </Grid>
        </Container>
      </div>
    </div>
  )
}
