import * as React from 'react'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { SurveyModal } from '../Modals/surveyModal'
import { Nav } from '../nav/NavDrawer'

const useStyles = makeStyles({
  about: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10%',
  },
  container: {
    marginTop: '1%',
    marginBottom: '10%',
    marginLeft: '30%',
    marginRight: '30%',
  },
  paragraph: {
    fontWeight: 'normal',
  },
  myPictureLarge: {
    height: '30rem',
  },
  myPictureSmall: {
    height: '15rem',
  },
})

export const Module = ({
  name,
  video,
  description,
  hasSurvey,
  survey,
  nextModule,
  nextModuleHref,
  previousModule,
  previousModuleHref,
  pdfLink,
  target,
}: ModuleProps) => {
  const [open, setOpen] = React.useState(window.innerWidth < 720 ? false : true)

  const handleClick = () => {
    setOpen(!open)
  }

  const classes = useStyles()

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setOpen(false)
    }
  }

  const onDownload = () => {
    const link = document.createElement('a')
    link.download = pdfLink
    link.href = './' + pdfLink
    link.click()
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <div>
      <Nav handleNav={handleClick} />
      <div
        className={classes.about}
        style={{
          paddingLeft: open ? '300px' : '0px',
          transform: 'scale(1)',
          transition: '0.25s all ease',
        }}
      >
        <SurveyModal modalOpen={hasSurvey} modalLink={survey} />
        <Container className={classes.container}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignContent={'center'}
            justifyContent={'center'}
          >
            <div>
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h4" paragraph>
                  <strong>{name}</strong>
                </Typography>
                <Grid
                  container
                  alignContent={'center'}
                  justifyContent={'center'}
                  md={12}
                >
                  <Card sx={{ width: '80%' }}>
                    <CardMedia
                      component="iframe"
                      title={name}
                      src={video}
                      sx={{ height: 520 }}
                      allow="fullscreen;"
                    />
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h5" paragraph sx={{ paddingTop: '1%' }}>
                  <strong>Module Description</strong>
                </Typography>
                <Grid
                  container
                  spacing={8}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={12}>
                    <Typography variant="h6" paragraph textAlign={'center'}>
                      {description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              spacing={0}
              alignContent={'center'}
              justifyContent={'space-between'}
              paddingTop="25px"
              md={8}
            >
              <Button
                variant="contained"
                disabled={false}
                startIcon={<ArrowBackIosIcon fontSize="large" />}
                href={previousModuleHref}
                sx={{
                  background:
                    'linear-gradient(to right bottom, #1F4D63, #47989D)',
                  '&:hover': {
                    backgroundColor: '#1F4D63',
                  },
                }}
              >
                <strong>Back</strong>
              </Button>
              <Button
                variant="contained"
                startIcon={<PictureAsPdfIcon fontSize="large" />}
                onClick={onDownload}
                sx={{
                  background:
                    'linear-gradient(to right bottom, #1F4D63, #47989D)',
                  '&:hover': {
                    backgroundColor: '#47989D',
                  },
                }}
              >
                <strong>Module PDF</strong>
              </Button>
              <Button
                variant="contained"
                disabled={false}
                endIcon={<ArrowForwardIosIcon fontSize="large" />}
                href={nextModuleHref}
                sx={{
                  // borderRadius: 8,
                  background:
                    'linear-gradient(to right bottom, #1F4D63, #47989D)',
                  '&:hover': {
                    backgroundColor: '#0C2331',
                  },
                }}
                target={target}
              >
                <strong>Next</strong>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

interface ModuleProps {
  name: string
  video: string
  description: string
  hasSurvey: boolean
  survey: string
  nextModule: string
  nextModuleHref: string
  previousModule: string
  previousModuleHref: string
  pdfLink: string
  target: string
}
