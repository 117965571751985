import * as React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export const ModuleEntry = ({
  name,
  videos,
  websites,
  worksheets,
  resources,
}: ModuleEntryProps) => {
  const websiteOrWorksheetSection = websites.length > 0 ? websites : worksheets
  const websiteOrWorksheetTitle =
    websites.length > 0 ? 'Websites' : 'Worksheets'

  return (
    <div>
      <Grid container sx={{ paddingTop: '3%' }}>
        <Grid item>
          <Typography variant="h4" paragraph>
            {name}
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Grid container spacing={12}>
          <Grid item md={4} alignItems="center" justifyContent="center">
            <Typography variant="h5" paragraph>
              <strong>Videos:</strong>
            </Typography>
            {videos.length > 0 ? (
              videos.map(video => (
                <Link key={video.href} href={video.href} target="_blank">
                  <Typography paragraph>{video.description}</Typography>
                </Link>
              ))
            ) : (
              <Typography paragraph>N/A</Typography>
            )}
          </Grid>
          <Grid item md={4} alignItems="center" justifyContent="center">
            <Typography variant="h5" paragraph>
              <strong>{websiteOrWorksheetTitle}:</strong>
            </Typography>
            {websiteOrWorksheetSection.map(entry => (
              <Link key={entry.href} href={entry.href} target="_blank">
                <Typography paragraph>{entry.description}</Typography>
              </Link>
            ))}
          </Grid>
          <Grid item md={4} alignItems="center" justifyContent="center">
            <Typography variant="h5" paragraph>
              <strong>Resources:</strong>
            </Typography>
            {resources.map(resource => (
              <Link key={resource.href} href={resource.href} target="_blank">
                <Typography paragraph>{resource.description}</Typography>
              </Link>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

type ResourceEntry = {
  description: string
  href: string
}

interface ModuleEntryProps {
  name: string
  videos: ResourceEntry[]
  websites: ResourceEntry[]
  worksheets: ResourceEntry[]
  resources: ResourceEntry[]
}
