import * as React from 'react'

import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import ChecklistIcon from '@mui/icons-material/Checklist'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import EmailIcon from '@mui/icons-material/Email'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import LinkIcon from '@mui/icons-material/Link'
import MenuIcon from '@mui/icons-material/Menu'
import PollIcon from '@mui/icons-material/Poll'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import WavingHandIcon from '@mui/icons-material/WavingHand'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

import logo from '../../img/next-stop-logo.png'

const drawerWidth = 300

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  flexShrink: 0,
}))

export const Nav = ({ handleNav }: NavProps) => {
  const [navOpen, setNavOpen] = React.useState(
    window.innerWidth < 720 ? false : true
  )
  const [openSubList, setOpenSubList] = React.useState(true)

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setNavOpen(false)
    }
  }

  const handleSubListClick = () => {
    setOpenSubList(!openSubList)
  }

  const handleClick = () => {
    setNavOpen(!navOpen)
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <Box sx={{ display: 'flex' }} paddingBottom="120px">
      {/* <CssBaseline /> */}
      <AppBar
        open={navOpen}
        elevation={0}
        sx={{ borderBottom: '1px solid #E0E0E0' }}
        position="absolute"
      >
        <Toolbar sx={{ background: 'white' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              handleNav()
              handleClick()
            }}
            edge="start"
            sx={{ mr: 2, ...(navOpen && { display: 'none' }) }}
          >
            <MenuIcon sx={{ color: 'black' }} />
          </IconButton>
          <Grid
            container
            spacing={0}
            direction="column"
            alignContent={'center'}
            justifyContent={'center'}
            paddingRight={navOpen ? '0px' : '50px'}
          >
            <Link href="/">
              <img src={logo} alt="onward" height="150px" />
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={navOpen}
      >
        <DrawerHeader>
          <Grid
            container
            spacing={0}
            direction="column"
            alignContent={'center'}
            justifyContent={'center'}
            minHeight={'150px'}
          >
            <Link href="/">
              <img src={logo} alt="onward" height="150px" />
            </Link>
          </Grid>
          <IconButton
            onClick={() => {
              handleNav()
              handleClick()
            }}
          >
            <MenuIcon sx={{ color: 'black' }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key={'Introduction'} disablePadding>
            <ListItemButton href="/">
              <ListItemIcon>
                <EmojiPeopleIcon />
              </ListItemIcon>
              <ListItemText primary={'Introduction'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Intake Form'} disablePadding>
            <ListItemButton
              href="https://forms.gle/n19rHpGcahBshRGW8"
              target="_blank"
            >
              <ListItemIcon>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText primary={'Intake Form'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Pre-Survey'} disablePadding>
            <ListItemButton
              href="https://forms.gle/q2xCngpfPSsA9ntZA"
              target="_blank"
            >
              <ListItemIcon>
                <PollIcon />
              </ListItemIcon>
              <ListItemText primary={'Pre-Survey'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Modules'} disablePadding>
            <ListItemButton onClick={handleSubListClick}>
              <ListItemIcon>
                <ViewModuleIcon />
              </ListItemIcon>
              <ListItemText primary={'Modules'} />
              {openSubList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSubList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} href="/module-1">
                <ListItemIcon>
                  <WavingHandIcon />
                </ListItemIcon>
                <ListItemText primary="Module 1: Welcome and Introduction" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} href="/module-2">
                <ListItemIcon>
                  <DepartureBoardIcon />
                </ListItemIcon>
                <ListItemText primary="Module 2: Trip Planning" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} href="/module-3">
                <ListItemIcon>
                  <LightbulbIcon />
                </ListItemIcon>
                <ListItemText primary="Module 3: Problem Solving and Decision Making" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} href="/module-4">
                <ListItemIcon>
                  <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText primary="Module 4: Social Skills and Emotional Regulation" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} href="/module-5">
                <ListItemIcon>
                  <VolunteerActivismIcon />
                </ListItemIcon>
                <ListItemText primary="Module 5: Resources and Support" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} href="/module-6">
                <ListItemIcon>
                  <ThumbUpAltIcon />
                </ListItemIcon>
                <ListItemText primary="Module 6: Summary" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItem key={'Post-Survey'} disablePadding>
            <ListItemButton
              href="https://forms.gle/GEMjeW5b65Qi1piM9"
              target="_blank"
            >
              <ListItemIcon>
                <PollIcon />
              </ListItemIcon>
              <ListItemText primary={'Post-Survey'} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key={'Resources'} disablePadding>
            <ListItemButton href="/resources">
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={'Resources'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Contact'} disablePadding>
            <ListItemButton href="/contact">
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={'Contact'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={navOpen}></Main>
    </Box>
  )
}

interface NavProps {
  handleNav: () => void
}
